import React from "react";
import "./FooterStyles.css";
import CompanyLogo from "../assets/coaltech-logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return(
    <div className="footer">
      <div className="footer-top">
        <div>
          <img src={CompanyLogo} alt="img" className="nav-image" />
          <p> Coaltech Industries(Pvt) Ltd </p>
        </div>
        <div>
          <p><i className="fa-solid fa-location-dot" id="icon-contatc-footer"></i> Maniktenna Estate, Unaleeya, Kithalawa, Sri Lanka. </p>
          <p><i className="fa-solid fa-phone" id="icon-contatc-footer"></i> +9477 777 7736 </p>
          <p><i className="fa-solid fa-envelope" id="icon-contatc-footer"></i><a id="icon-contatc-footer" href="mailto:coaltech@sltnet.lk">coaltech@sltnet.lk</a> </p>
        </div>
        <div>
          <a href="https://web.facebook.com/profile.php?id=100091270342026" target="_blank">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="https://twitter.com/Coaltechcarbon?t=M4c1p-ekfProsJ3my7SEBQ&s=09" target="_blank">
              <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>

      <div className="footer-bottom">
        <div>
          <Link to="/"> Home </Link><br/>
        </div>
        <div>
          <Link to="/about-us"> About Us </Link><br/>
        </div>
        <div>
          <Link to="/applications"> Water Treatment </Link><br/>
          <Link to="/applications"> Air Purification </Link><br/>
          <Link to="/applications"> Food and Pharmaceuticals <br/> Industries </Link><br/>
          <Link to="/applications"> Personal Protection<br/> Industries </Link>
        </div>
        <div>
          <Link to="/product-services"> Products and Services </Link>
        </div>
        <div>
          <Link to="/manufacturingfacility"> Manufacturing Facility </Link>
        </div>
        <div>
          <Link to="/contactus"> Conatct Us </Link><br/>
          <Link to="/news"> News </Link>
        </div>
        
      </div>

      <div className="footer-final">
        <div>
          <p> Powered by <a href="https://durnglobal.com/" target={"_blank"}> DurnGlobal</a></p>
        </div>
        <div>
          <p> Copyright © 2023 Coaltech Industries(Pvt) Ltd | All rights reserved</p>
        </div>
        <div>
          <p><Link to="/"> Privacy Policy</Link> </p>
        </div>
      </div>

    </div>
  )
}

export default Footer;
