import React from "react";
import './AbourUsDetailsStyles.css';
import Image from "../assets/aboutus/coaltech-company.jpg";
import ImageTwo from "../assets/aboutus/coaltech-production-chain.jpg";
import ApplicationData from "./ApplicationData";

const AbourUsDetails = () => {
    return (
        <div className="aboutus-details">
          {/* <h1> About Us </h1> */}
          {/* <p> Details of the application </p> */}
          <ApplicationData
            className="first-des"
            heading="An overview of Coaltech Industries"
            text="Established as a sole proprietorship in 1986 engaged in trading of coconut shell charcoal, catering to both domestic and foreign markets was blessed by continuous growth enabling the business to become a limited liability company in 2008 with a vision to expand its horizon to untouched markets in the industry. Today, having a factory with evolving technology it is geared to climb greater heights. From the inception it was our motto to treat “customer as the king” and it will never be written off in our future endeavours."
            imageSource={Image}
            imageSource2={ImageTwo}
          />
          
        </div>
      );
}

export default AbourUsDetails;