import "./ImageSliderStyles.css";
import SliderImageOne from "../assets/home/coaltech-tecknology.jpg"
import SliderImageTwo from "../assets/home/coaltech-work.jpg"
import SliderImageThree from "../assets/home/coaltech-application.jpg"
import SliderImageFour from "../assets/home/coaltech-product-and-service.jpg"
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function ImageSlider(){

    return(
        <Carousel  autoPlay interval={4000} infiniteLoop showStatus={false} animationHandler="fade" showThumbs={false} showArrows={true} stopOnHover={false}>
            
                <div className="image-slider">
                    <img src={SliderImageOne} className="slider-img"/>
                    <div className="image-slider-text">
                        <p> Facility with reliable </p>
                        <h1>Technology and Manpower</h1>
                    </div>
                </div>
                <div className="image-slider">
                    <img src={SliderImageTwo}  className="slider-img"/>
                    <div className="image-slider-text">
                        <p> We take pride in our</p>
                        <h1> Efficiency and <br/>Effectiveness</h1>
                    </div>
                </div>
                <div className="image-slider">
                    <img src={SliderImageThree}  className="slider-img"/>
                    <div className="image-slider-text">
                        <p> Reliable delivery to </p>
                        <h1> Customer Satisfaction</h1>
                    </div>
                </div >
                <div className="image-slider">
                    <img src={SliderImageFour}  className="slider-img"/>
                    <div className="image-slider-text">
                        <p> Delivering products with </p>
                        <h1> Quality </h1>
                    </div>
                </div >
                        
            </Carousel>
    )
}

export default ImageSlider;