import React from "react";
import ApplicationData from "./ApplicationData";
import "./ApplicationDetailsStyled.css";
import WaterTreatment from "../assets/home/water-treatment.jpg"
import AirPurification from "../assets/home/air-purification.jpg"
import PersonalProtection from "../assets/home/personal-protection.jpg"
import PharmaceuticalsProtection from "../assets/home/coaltech-pharmaceuticals.jpg"
import GoldMining from "../assets/home/gold-mining.jpg"
import Decolourization from "../assets/home/decolourization.jpg"
import CigaretteFilter from "../assets/home/cigarette-filter.jpg"
import OdourRemoval from "../assets/home/odour-removal.jpg"
import Grade1 from "../assets/home/coaltech-carbon-grade1.jpg"
import Grade2 from "../assets/home/coaltech-carbon-grade2.jpg"
import Grade3 from "../assets/home/coaltech-carbon-grade3.jpg"
import Grade4 from "../assets/home/coaltech-carbon-grade4.jpg"
import Grade5 from "../assets/home/coaltech-carbon-grade5.jpg"

const ApplicationDetails = () => {
  return (
    <div className="application-details">
      {/* <h1> Application of Active Carbon </h1>
      <p> Details of the application </p> */}

      <ApplicationData
        className="first-des"
        heading="Water Treatment"
        text="This can be used in combination with relevant materials/Chemicals
        for water purification as absorption is the finest within reach
        technology for elimination of organic materials in water."
        imageSource={Grade2}
        imageSource2={WaterTreatment}
      />
      <ApplicationData
        className="first-des-reverse"
        heading="Air Purification"
        text="Together with other chemicals and materials activated carbon 
        is used to eliminate organic chemical contaminants from industrial 
        and other emissions."
        imageSource={AirPurification}
        imageSource2={Grade1}
      />
      <ApplicationData
        className="first-des"
        heading="Food and Pharmaceuticals Industries"
        text="Activated carbon plays an important role in decaffeination
         processes, manufacturing of various sweeteners and glycerine,  
         manufacture of Vitamins and chemical purification processes. 
         It is also used in the tobacco industry and manufacture of various 
         beverages."
        imageSource={Grade5}
        imageSource2={PharmaceuticalsProtection}
      />
      <ApplicationData
        className="first-des-reverse"
        heading="Personal Protection Industries"
        text="Especially in the sectors where respiratory purification is 
        needed to filter contaminated air into breathable air, filters 
        compromising activated carbons are widely used as well as in the 
        protective clothing sector, protection against nuclear and 
        biological agents, the main vital component is activated carbon."
        imageSource={PersonalProtection}
        imageSource2={Grade3}
      />
      <ApplicationData
        className="first-des"
        heading="Gold Mining Industry"
        text="Utilising the carbon slurry method  in the gold mining industry 
        coconut shell activated carbon can be used to segregate gold efficiently; 
        alternatively the heap leaching method also can be used with the same. 
        The strong adsorption  power and its stronger strength derives an 
        effective result."
        imageSource={Grade4}
        imageSource2={GoldMining}
      />
      <ApplicationData
        className="first-des-reverse"
        heading="Decolourization Using Active Carbon"
        text="Active carbon is a commonly used component nowadays for decolourization.
        Simple theory of minimal quantities of decolorizing carbon furnishes  a 
        substantial surface area to which large coloured molecules can be absorbed."
        imageSource={Decolourization}
        imageSource2={Grade3}
      />
      <ApplicationData
        className="first-des"
        heading="Cigarette Filter"
        text="Giving the smokers a sigh of relief active carbon filters helps to 
        reduce carcinogens and tar. Most harmful substances are absorbed giving a 
        cleaner and smoother smoke."
        imageSource={Grade5}
        imageSource2={CigaretteFilter}
      />
      <ApplicationData
        className="first-des-reverse"
        heading="Odour Removal"
        text="Activated carbon systems are used to control odours that can be 
        present in air in various sewage systems. Mass scale production factories 
        have a significant negative impact on the environment. As a solution to this, 
        various specialist companies have emerged providing solutions with activated carbon."
        imageSource={OdourRemoval}
        imageSource2={Grade3}
      />
    </div>
  );
};

export default ApplicationDetails;
