import React, { Fragment ,useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./ContactDetailsStyles.css";

const ContactDetails = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ej651mc', 'template_fxhksv3', form.current, 'i_0BlwWm0CmWYuaW4')
        .then((result) => {
            console.log(result.text);
            alert(["Email sent sucessfully"]);
            window.location.reload(true)
        }, (error) => {
            console.log(error.text);
            alert(["Error to sent Email. Please Try Again"]);
        });
    };

  return (
    <Fragment>
        <section className="contact">
            <div className="contact-heading">
                <h2>If you have any inquiries, We'd love to hear from you!</h2>
            </div>
            <div className="contact-container">
                <div className="row-contact">
                    <div className="column-contact">
                        <div className="contact-widget">

                            <div className="contact-widget-item">
                                
                                <div className="text">
                                    <h5>Office Address</h5>
                                    <p> Maniktenna Estate, Unaleeya, Kithalawa, Sri Lanka.</p><hr className="hr-contact"/>
                                </div>
                            </div>

                            <div className="contact-widget-item">
                                <div className="icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="text">
                                    <h5>Phone</h5>
                                    <p>+9477 777 7736</p>
                                </div>
                            </div>

                            <div className="contact-widget-item">
                                <div className="icon">
                                    <i className="fa-solid fa-envelope"></i>
                                </div>
                                <div className="text">
                                    <h5>Email</h5>
                                    <p>coaltech@sltnet.lk</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="column-contact">
                        <div className="contact-form">
                            <form enctype="multipart/form-data" method="post" ref={form} onSubmit={sendEmail}>
                                <p className="form-field-topic">Name <span className="red-star">*</span></p>
                                <input type="text" name="user_name" placeholder='Name' required/>
                                <p className="form-field-topic">Request Type <span className="red-star">*</span></p>
                                <select className="type" name="request_type" required>
                                    <option value="">Select</option>
                                    <option value="GeneralMessage">General Message</option>
                                    <option value="QuotationRequest">Quotation Request</option>
                                </select>
                                <p className="form-field-topic">Email <span className="red-star">*</span></p>
                                <input type="email" name="user_email" placeholder='Email' required/>
                                <p className="form-field-topic">Telephone</p>
                                <input type="tel" name="phone_number" placeholder='Mobile' />
                                <p className="form-field-topic">Request Body <span className="red-star">*</span></p>
                                <textarea name="message" cols="30" rows="10" placeholder='Your Requirement' required/>
                                {/* <label for="myfile">Select a file:</label><input type="file" className="myfile" name="my_file" /><br /> */}
                                <input type="submit" value="Send" className="site-btn"/>
                            </form>
                        </div>
                    </div>

                </div>
                
            </div>

            <div className="contact-container-map">
                <div className="row-contact">
                    <div className="map-column">
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.957379702188!2d80.11236361523258!3d7.469958813507863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae327a6975188e5%3A0x36a0ce46a46db136!2sCoaltech%20Industries%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1676626815225!5m2!1sen!2slk" 
                                width="600" 
                                height="450" 
                                style={{border:0}} 
                                allowfullscreen="" 
                                loading="lazy" 
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Fragment>
  )
}

export default ContactDetails