import React ,{  useEffect,useState } from 'react'
import "./ChatWhatsAppStyles.css"
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const ChatWhatsApp = () => {

    return (
        <div className="chat-top-to-btm">
                <WhatsAppWidget
                    phoneNo="94742013002"
                    position="right"
                    widgetWidth="300px"
                    widgetWidthMobile="260px"
                    autoOpen={false}
                    autoOpenTimer={5000}
                    messageBox={true}
                    messageBoxTxt="Hi Team, is there any related service available ?"
                    iconSize="60"
                    iconColor="white"
                    iconBgColor="#67D449"
                    headerIcon=""
                    headerIconColor="#e3be4a"
                    headerTxtColor="#fff"
                    headerBgColor="#516b52"
                    headerTitle="Coaltech Industries"
                    headerCaption="Online"
                    bodyBgColor="#f5f5f0"
                    chatPersonName="Coaltech"
                    chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
                    footerBgColor="#516b52"
                    placeholder="Type a message.."
                    btnBgColor="#e3be4a"
                    btnTxt="Start Chat"
                    btnTxtColor="#516b52"
                />
        </div>
    )
}

export default ChatWhatsApp