import "./HeroOneSideImageStyles.css"
import Edb from "../assets/home/coaltech-edb.jpg"

function HeroOneSideImage(){
    return(
        <div className="hero-one-main">
            <div className="hero-one-column">
                <p className="hero-one-text">We are proud to be registered with the Sri Lanka Export Development Board.</p>
                <a href="https://www.srilankabusiness.com/exporters-directory/company-profiles/coaltech-industries-pvt-ltd/"><p className="hero-one-button">Read More</p></a>
            </div>
            <div className="hero-one-column-1">
                <img src={Edb} />
            </div>
        </div>
    );
}

export default HeroOneSideImage;