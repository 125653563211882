import React from 'react'
import { Link } from 'react-router-dom';
import "./ApplicationShortStyles.css"

function ApplicationShort (props) {
    return (
        <div className="application-card">
            <Link to="/applications">
            <div className="application-image">
                
                {props.applicationImage ? 
                    <img src={props.applicationImage} alt="img" /> : 
                    <div><h4>{props.applicationHeading}</h4>
                        {/* <p id="read-more">Read more .. </p> */}
                    </div>
                }
                
                <div className="overlay">
                    <h4>{props.applicationHeading}</h4>
                </div>
            </div>
            </Link>
          
        </div>
      );
}

export default ApplicationShort;