import React from "react";
import ImageSlider from "../components/ImageSlider";
import Navbar from "../components/Navbar";
import News from "../components/NewsComponent";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ChatWhatsApp from "../components/ChatWhatsApp";
import ApplicationSortData from "../components/ApplicationSortData";
import AboutShort from "../components/AboutShort";
import HomeDecs from "../components/HomeDecs";
import Slider from "../components/Slider";
import HeroOneSideImage from "../components/HeroOneSideImage";
import TwitterPost from "../components/TwitterPosts";

function Home() {
  return (
    <>
      <Navbar />
      <ChatWhatsApp/>
      <ScrollToTop/>
      <ImageSlider />
      <AboutShort/>
      <HomeDecs/>
      <ApplicationSortData/>
      <HeroOneSideImage/>
      <Slider/>
      <TwitterPost/>
      <Footer/>
    </>
  );
}

export default Home;
