import "./NewsStyles.css";
import NewsData from "./NewsData";
import NewsImageOne from "../assets/news/coaltech-product-and-growth.jpg"
import NewsImageTwo from "../assets/news/coaltech-product-and-news-skincare.jpg"
import NewsImageThree from "../assets/news/coaltech-product-and-segmentation.jpg"

function News() {
  return (
    <div className="news">
      <p> Latest </p>
      <h1>News and Events</h1>
      <p> See the latest news and events from Coaltech </p>
      <div className="newscard">
        <NewsData
          image={NewsImageOne}
          heading="Impressive growth of 7.1% is expected for Activated Carbon Market in next decade"
          text="GCC market for activated carbon is expected to grow to a value of US$ 133 million towards the end of the year 2023 as the total market is expected to reach US$ 286 million when reaching the end of 2033. It clearly showcases a growth with a CAGR of 7.1% as the demand for increasing clean drinking water and for purification of air."
        />
        <NewsData
          image={NewsImageTwo}
          heading="Incline of sales in skin care products with Activated carbon"
          text="With more and more products coming into the market with activated carbon the skin care industry has also incorporated the same to enhance their product quality. The growing industry is slowly making a note in the activated carbon industry."
        />
        <NewsData
          image={NewsImageThree}
          heading="Segmentation of activated carbon industry"
          text="The present day segmentation compromises with 3 main groups. Powdered, Granular and Extruded activated carbons.  The fact that impresses the most is all 3 sectors are performing and growing."
        />
      </div>
    </div>
  );
}

export default News;
