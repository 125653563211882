import "./NewsStyles.css";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

function TwitterPost(){
    return(
        <div className="news">
            <TwitterTimelineEmbed
            sourceType="profile"
            screenName="Coaltechcarbon"
            options={{height: 600}}
            />
        </div>
    )
}

export default TwitterPost;