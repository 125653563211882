const people = [
  {
    id: 1,
    image: "/images/Person1.jpeg",
    name: "Chaminda Fernando",
    title: "",
    quote:
      "It is a pleasure to work with Coalterch, I hope they will keep up the good work.",
  },
  {
    id: 2,
    image: "/images/Person2.png",
    name: "P. Kularatne",
    title: "",
    quote:
      "I wish for their development and sustainability in this volatile period as they keep on delivering better products",
  }
];

export default people;
