import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import NewsHeadingImg from "../assets/news/coaltech-news.jpg";
import Footer from "../components/Footer";
import NewsComponent from "../components/NewsComponent";
import ScrollToTop from "../components/ScrollToTop";
import ChatWhatsApp from "../components/ChatWhatsApp";

function News() {
  return (
    <>
      <Navbar />
      <ScrollToTop/>
      <ChatWhatsApp/>
      <Hero
        cName="hero-mid"
        heroImg={NewsHeadingImg}
        title="News"
        text="News"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <NewsComponent/>
      <Footer/>
    </>
  );
}

export default News;
