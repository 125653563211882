export const MenuItems = [
  {
    title: "Home",
    url: '/',
    cName: "nav-links",
    icon: ""
  },
  {
    title: "About Us",
    url: "/about-us",
    cName: "nav-links",
    icon: ""
  },
  {
    title: "Product and Services",
    url: "/product-services",
    cName: "nav-links",
    icon: ""
  },
  {
    title: "Applications",
    url: '/applications',
    cName: "nav-links",
    icon: ""
  },
  {
    title: "Manufacturing Facility",
    url: "/manufacturingfacility",
    cName: "nav-links",
    icon: ""
  },
  {
    title: "News",
    url: "/news",
    cName: "nav-links",
    icon: ""
  },
  {
    title: "Contact Us",
    url: "/contactus",
    cName: "nav-links-btn",
    icon: ""
  }
];
