import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import ImgOne from "../assets/manufacturingfacility/1.jpg"
import ImgTwo from "../assets/manufacturingfacility/2.jpg"
import ImgThree from "../assets/manufacturingfacility/3.jpg"
import ImgFour from "../assets/manufacturingfacility/4.jpg"
import ImgFive from "../assets/manufacturingfacility/5.jpg"
import ImgSix from "../assets/manufacturingfacility/6.jpg"
import ImgSeven from "../assets/manufacturingfacility/7.jpg"
import ImgeEight from "../assets/manufacturingfacility/8.jpg"
import ImgeNine from "../assets/manufacturingfacility/9.jpg"
import ImgeTen from "../assets/manufacturingfacility/10.jpg"
import ImgeEleven from "../assets/manufacturingfacility/11.jpg"
import ImgeTwelve from "../assets/manufacturingfacility/12.jpg"
import ImgeThirteen from "../assets/manufacturingfacility/13.jpg"
import ImgeFourteen from "../assets/manufacturingfacility/14.jpg"
import ImgeFifeteen from "../assets/manufacturingfacility/15.jpg"
import ImgeSixteen from "../assets/manufacturingfacility/16.jpg"
import ImgeSeventeen from "../assets/manufacturingfacility/17.jpg"
import ImgeEighteen from "../assets/manufacturingfacility/18.jpg"
import ImgeNineteen from "../assets/manufacturingfacility/19.jpg"
import ImgeTwente from "../assets/manufacturingfacility/20.jpg"
import "./GalleryStyled.css"

const Gallery = () => {

    let data = [
        {
            id:1,
            imgSrc:ImgTwo
        },
        {
            id:2,
            imgSrc:ImgFive
        },
        {
            id:3,
            imgSrc:ImgThree
        },
        {
            id:4,
            imgSrc:ImgFour
        },
        {
            id:5,
            imgSrc:ImgOne
        },
        {
            id:6,
            imgSrc:ImgSix
        },
        {
            id:7,
            imgSrc:ImgSeven
        },
        {
            id:8,
            imgSrc:ImgeEight
        },
        {
            id:9,
            imgSrc:ImgeNine
        },
        {
            id:10,
            imgSrc:ImgeTen
        },{
            id:11,
            imgSrc:ImgeEleven
        },
        {
            id:12,
            imgSrc:ImgeTwelve
        },
        {
            id:13,
            imgSrc:ImgeThirteen
        },
        {
            id:14,
            imgSrc:ImgeFourteen
        },
        {
            id:15,
            imgSrc:ImgeFifeteen
        },
        {
            id:16,
            imgSrc:ImgeSixteen
        },
        {
            id:17,
            imgSrc:ImgeSeventeen
        },
        {
            id:18,
            imgSrc:ImgeEighteen
        },
        {
            id:19,
            imgSrc:ImgeNineteen
        },
        {
            id:20,
            imgSrc:ImgeTwente
        }
    ]

    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImageSrc] = useState('');

    const getImg = (imgSrc) => {
        setTempImageSrc(imgSrc);
        setModel(true);
    }

    return(
        <>
            <div className={model ? "model open" : "model"} onClick={() => setModel(false)}>
                <img src={tempimgSrc}/>
                <IoClose onClick={() => setModel(false)}/>
            </div>
            <div className="gallery">
                {data.map((item, index)=>{
                    return(
                        <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} alt="" />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Gallery;