import React from "react";
import { Link } from "react-router-dom";
import "./HeroStyles.css";

function Hero(props) {
  return (
    <>
      <div className={props.cName}>
        
        {props.heroImg ? <img src={props.heroImg} className="hero-image"/> : " " }
        
        <div className="hero-text">
          <h1> {props.title} </h1>
          {props.heroImg  ? <p> <Link to="/"> Home </Link> / {props.text} </p> : <p> {props.text} </p>}
          <a href={props.url} className={props.btnClass}>
            {" "}
            {props.btnTxt}{" "}
          </a>
          {props.p2 ? <p className="parah-2">{props.p2}</p> : ""}
        </div>
      </div>
    </>
  );
}

export default Hero;
