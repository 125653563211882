import React from "react";
import { Component } from "react";
import "./ApplicationDetailsStyled.css";

class ApplicationData extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="application-des-text">
          <h2> {this.props.heading} </h2>
          <p>{this.props.text}</p>
          {this.props.text2 ? <p>{this.props.text2}</p> : ""}
          {this.props.text3 ? <p>{this.props.text3}</p> : ""}
          {this.props.text4 ? <p>{this.props.text4}</p> : ""}
        </div>
        <div className="application-img">
          <img alt="img" src={this.props.imageSource} />
          <img alt="img" src={this.props.imageSource2} />
          
        </div>
      </div>
    );
  }
}

export default ApplicationData;
