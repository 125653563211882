import "./AboutShortStyles.css"
import ImageForAbout from "../assets/home/product-convert.jpg"

function HomeDecs(){
    return(
        <div className="about-short-main">
            <div className="about-short-img-other">
                <img src={ImageForAbout} alt="" />
            </div>
            <div className="about-short">
                <div className="about-sort-left-right">
                </div>
                <p>The successful project completion history of Coaltech meets a significant landmark through 
                    the satisfactory supplies made to key global players in the industry. Being customer centric 
                    with a dedicated team, supplies have been made for challenging demands and quality.  Timely 
                    and efficient execution of orders has proven to keep the flow of production of our valuable 
                    customers uninterrupted.
                </p>
            
                {/* <a href="/manufacturingfacility"><p className="about-short-button">Read more</p></a> */}
                
            </div>
            
        </div>
    );
}

export default HomeDecs;