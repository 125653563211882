import "./AboutShortStyles.css"
import ImageForAbout from "../assets/home/coaltech-company.jpg"
import { Link } from "react-router-dom";

function AboutShort(){
    return(
        
        <div className="about-short-main">
            <div className="about-short">
                <h4>Welcome To <span> Coaltech Industries</span></h4>
                <hr /><br />
                <div className="about-sort-left-right">
                    {/* <h1>37+</h1>
                    <p>YEARS OF LONG LASTING AND MUTUALLY REWARDING RELATIONSHIPS IN THE INDUSTRY.</p> */}
                </div>
                <p>With over 30 years of experience, we guarantee our supply on time with quality assurances accompanied by unbeatable prices in the market.</p>
            
                <Link to="/about-us"><p className="about-short-button">Read more</p></Link>
                
            </div><hr />
            <div className="about-short-img">
                <img src={ImageForAbout} alt="" />
            </div>
        </div>
    );
}

export default AboutShort;