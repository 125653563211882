import "./ApplicationDetailsStyled.css"
import ApplicationData from "./ApplicationData"
import CocountShell from "../assets/product&services/1.jpg"
import ActivatedCarbon from "../assets/product&services/2.jpg"

function ProductAndService () {

    return(
        <div className="application-details">
            <ApplicationData
                className="first-des-reverse"
                heading="Coconut shell activated carbon"
                text="The main source being the coconut tree, which has a life span of over 70 years is a renowned renewable resource. The high hardness and its magnificent filtration performance makes it the perfect source for many treatment  applications."
                text2=" Our manufacturing facility is based in the centre of what is known as the coconut triangle of Sri Lanka. It has made our sourcing procedure easier and competitive.  The general public in the area is also well conversant with the subject, which has made meeting the quality aspects of the products  a touch easier."
                text3=" Since coconut shells are not a scarce resource in and around the manufacturing facility, we have opted to give the best and the most competitive prices in the market."
                text4="The customers who provide continuous orders are being given special priority and very competitive prices upon signing long term agreements.  The special feature of coaltech is the flexibility and open for discussion at all times."
                imageSource={CocountShell}
                imageSource2={ActivatedCarbon}
            />
        </div>
        
    )
}

export default ProductAndService;