import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import ApplicationMain from "../assets/applications/coaltech-application.jpg";
import Footer from "../components/Footer";
import ApplicationDetails from "../components/ApplicationDetails";
import ScrollToTop from "../components/ScrollToTop";
import ChatWhatsApp from "../components/ChatWhatsApp";

function Applications() {
  return (
    <>
      <Navbar />
      <ScrollToTop/>
      <ChatWhatsApp/>
      <Hero
        cName="hero-mid"
        heroImg={ApplicationMain}
        title="Applications"
        text="Applications"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <ApplicationDetails/>
      <Footer/>
    </>
  );
}

export default Applications;
