import ApplicationShort from "./ApplicationShort";
import "./ApplicationShortStyles.css"
import NewsImageOne from "../assets/news/coaltech-product-and-service.jpg"
import WaterTreatment from "../assets/home/water-treatment.jpg"
import AirPurification from "../assets/home/air-purification.jpg"
import PersonalProtection from "../assets/home/personal-protection.jpg"
import PharmaceuticalsProtection from "../assets/home/coaltech-pharmaceuticals.jpg"
import Decolourization from "../assets/home/decolourization.jpg"
import CigaretteFilter from "../assets/home/cigarette-filter.jpg"
import OdourRemoval from "../assets/home/odour-removal.jpg"
import GoldMining from "../assets/home/gold-mining.jpg"

function ApplicationSortData(){
    return (
        <div className="application">
            {/* <h2>Our Services & Application</h2> */}
            <div className="applicationcard">
                
                <ApplicationShort
                applicationImage={WaterTreatment}
                applicationHeading="Water Treatment"
                />
                <ApplicationShort
                applicationImage={AirPurification}
                applicationHeading="Air Purification"
                />
                <ApplicationShort
                applicationImage={PharmaceuticalsProtection}
                applicationHeading="Food and Pharmaceuticals Industries"
                />
                <ApplicationShort
                applicationImage={PersonalProtection}
                applicationHeading="Personal Protection Industries"
                />
                <ApplicationShort
                applicationImage={GoldMining}
                applicationHeading="Gold Mining Industry"
                />
                <ApplicationShort
                applicationImage={Decolourization}
                applicationHeading="Decolourization Using Active Carbon"
                />
                <ApplicationShort
                applicationImage={CigaretteFilter}
                applicationHeading="Cigarette Filter"
                />
                <ApplicationShort
                applicationImage={OdourRemoval}
                applicationHeading="Odour Removal"
                />
            </div>
        </div>
      );
}

export default ApplicationSortData;