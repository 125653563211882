import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import CustomersHeadingImg from "../assets/ourcustomer/coaltech-customers.jpg";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ChatWhatsApp from "../components/ChatWhatsApp";

function OurCustomers() {
  return (
    <>
      <Navbar />
      <ScrollToTop/>
      <ChatWhatsApp/>
      <Hero
        cName="hero-mid"
        heroImg={CustomersHeadingImg}
        title="Our Customers"
        text="Our Customers"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <Footer/>
    </>
  );
}

export default OurCustomers;
