import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/contactus/coaltech-contact.jpg";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ContactDetails from "../components/ContactDetails";
import ChatWhatsApp from "../components/ChatWhatsApp";

function ContactUs() {
  return (
    <>
      <Navbar />
      <ScrollToTop/>
      <ChatWhatsApp/>
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Contact Us"
        text="Contact Us"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <ContactDetails/>
      <Footer/>
    </>
  );
}

export default ContactUs;
