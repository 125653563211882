import react from "react"
import "./TeamMemberStyles.css"
import CeoImage from "../assets/aboutus/coaltech-ceo-md.jpg"
import DirectorOneImage from "../assets/aboutus/coaltech-director-1.jpg"
import DirectorTwoImage from "../assets/aboutus/coaltech-director-2.jpg"
import DirectorThreeImage from "../assets/aboutus/coaltech-director-3.jpeg"
import { FaRegEnvelopeOpen } from "react-icons/fa"

function TeamMember(){
    return(
        <div>
            <h2 className="tema-header">Board of Directors</h2>
            <p>An unmatched leadership with a pioneering vision</p>
            <br/>
            
            <div className="team-row">
                <div className="team-column">
                    <div className="team-card">
                        <img src={CeoImage} alt="" className="team-image"/>
                        <div className="team-container">
                            <h2 className="team-member-name">Sisira Kumara</h2>
                            <p className="team-title">CEO & Founder</p>
                            {/* <p className="team-member-email">coaltech@sltnet.lk</p> */}
                            {/* <FaRegEnvelopeOpen/> */}
                        </div>
                    </div>
                </div>

                <div className="team-column">
                    <div className="team-card">
                        <img src={DirectorOneImage} alt="" className="team-image"/>
                        <div className="team-container">
                            <h2 className="team-member-name">Ranjani Dharmawardana</h2>
                            <p className="team-title">Director</p>
                            {/* <p className="team-member-email">coaltech@sltnet.lk</p> */}
                        </div>
                    </div>
                </div>

                <div className="team-column">
                    <div className="team-card">
                        <img src={DirectorTwoImage} alt="" className="team-image"/>
                        <div className="team-container">
                            <h2 className="team-member-name">Bhagya Awanthie</h2>
                            <p className="team-title">Director</p>
                            {/* <p className="team-member-email">coaltech@sltnet.lk</p> */}
                        </div>
                    </div>
                </div>

                <div className="team-column">
                    <div className="team-card">
                        <img src={DirectorThreeImage} alt="" className="team-image"/>
                        <div className="team-container">
                            <h2 className="team-member-name">Heshan Malinda</h2>
                            <p className="team-title">Director</p>
                            {/* <p className="team-member-email">coaltech@sltnet.lk</p> */}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default TeamMember;