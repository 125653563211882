import React, { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/coaltech-logo.png";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <div className="NavBarMain">
        {/* <div className="information-section">
          <p><i className="fa-solid fa-phone" id="icon-contatc-footer"></i> +9477 777 7736 </p>
          <Link to="/">
            <i className="fa-brands fa-facebook-square"></i>
          </Link>
        </div> */}
        <nav className="NavbarItems">
          <Link to='/'><img src={CompanyLogo} alt="img" className="nav-image" /></Link>

          <div className="menu-icons" onClick={this.handleClick}>
            <i
              className={
                this.state.clicked ? "fa-solid fa-times" : "fa-solid fa-bars"
              }
            ></i>
          </div>

          <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
            {MenuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.url} className={item.cName}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Navbar;
