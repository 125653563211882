import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import ManufacturingFacilityImage from "../assets/manufacturingfacility/coaltech-manufacturing-facility.jpg";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ManufacturingComponent from "../components/ManufacturingComponent";
import ChatWhatsApp from "../components/ChatWhatsApp";
import Gallery from "../components/Gallery";

function ManufacruringFacility() {
  return (
    <>
      <Navbar />
      <ScrollToTop/>
      <ChatWhatsApp/>
      <Hero
        cName="hero-mid"
        heroImg={ManufacturingFacilityImage}
        title="Manufacturing Facility"
        text="Manufacturing Facility"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <ManufacturingComponent/>
      <Gallery/>
      <Footer/>
    </>
  );
}

export default ManufacruringFacility;
