import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import ProductAndServiceImage from "../assets/product&services/coaltech-product-and-service.jpg";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ChatWhatsApp from "../components/ChatWhatsApp";
import ProductAndService from "../components/ProductAndService";

function ProductAndServices() {
  return (
    <>
      <Navbar />
      <ScrollToTop/>
      <ChatWhatsApp/>
      <Hero
        cName="hero-mid"
        heroImg={ProductAndServiceImage}
        title="Product And Services"
        text="Product And Services"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <ProductAndService/>
      <Footer/>
    </>
  );
}

export default ProductAndServices;
