import "./ManufacturingComponentStyles.css"
import { GrTechnology } from "react-icons/gr"
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { RiEmotionHappyLine } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";

function ManufacturingComponent(){
    return(
        <div className="manufacturing-main">
            <div className="manufacturing-right">
                <h1>Manufacturing Facility</h1>
                <p>We are equipped with equipment to take on the most challenging 
                    orders and cater it on time. Reliable and well trained manpower 
                    gives us immense value as we look for the future with confidence.
                </p>
            </div>
            <div className="manufacturing-left">
                <p><GrTechnology/>&nbsp;&nbsp;Facility with reliable technology and manpower</p>
                <p><BsFillBrightnessHighFill/>&nbsp;&nbsp;We take pride in our efficiency and effectiveness</p>
                <p><RiEmotionHappyLine/>&nbsp;&nbsp;Reliable delivery to customer satisfaction</p>
                <p><TbTruckDelivery/>&nbsp;&nbsp;Delivering products with quality</p>
            </div>
        </div>
    );
}

export default ManufacturingComponent;