import React from "react";
import "./styles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import AboutUs from "./routes/AboutUs";
import Applications from "./routes/Applications";
import ProductAndServices from "./routes/ProductAndServices";
import ManufacruringFacility from "./routes/ManufacturingFacility";
import OurCustomers from "./routes/OurCustomer";
import News from "./routes/News";
import ContactUs from "./routes/ContactUs";
import StartWithTop from "./components/StartWithTop";

export default function App() {
  return (
    <div className="App"> 
      <StartWithTop>
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/product-services" element={<ProductAndServices/>} />
          <Route path="/applications" element={<Applications/>} />
          <Route path="/manufacturingfacility" element={<ManufacruringFacility/>} />
          <Route path="/ourcustomers" element={<OurCustomers/>} />
          <Route path="/news" element={<News/>} />
          <Route path="/contactus" element={<ContactUs/>} />
        </Routes>
      </StartWithTop> 
    </div>
  );
}
