import React from "react";
import { Link } from "react-router-dom";
import "./NewsStyles.css";

function NewsData(props) {
  return (
    <div className="news-card">
      <div className="news-image">
        <img src={props.image} alt="img" />
      </div>
      <h4>{props.heading}</h4>
      <p>{props.text}</p>
      <Link to="/news"><p id="read-more">Read more .. </p></Link>
    </div>
  );
}

export default NewsData;
