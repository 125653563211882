import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutUsImg from "../assets/aboutus/coaltech-about.jpg";
import Footer from "../components/Footer";
import AbourUsDetails from "../components/AboutUsDetails";
import ScrollToTop from "../components/ScrollToTop";
import TeamMember from "../components/TeamMember";
import ChatWhatsApp from "../components/ChatWhatsApp";

function AboutUs() {
  return (
    <>
      <Navbar />
      <ChatWhatsApp/>
      <ScrollToTop/>
      <Hero
        cName="hero-mid"
        heroImg={AboutUsImg}
        title="About Us"
        text="About Us"
        url=""
        btnClass="hide"
        btnTxt=""
      />
      <AbourUsDetails/>
      <Hero
        cName="hero"
        heroImg= ""
        title=" Managing Director/CEO's Message"
        text="'' From humble beginnings in 1986, it is with great pride that I have witnessed 
        the growth of Coaltech Industries over the years. Transforming from a sole proprietorship 
        to a limited liability company with a factory that could cater to the imperative demand makes 
        me proud of the achievement. I trust Coaltech will grow to become a global leader in the industry. ''"
        url="/"
        p2="''Sisira Kumara''"
        btnClass=""
        btnTxt=""
      />
      <TeamMember/>
      <Footer/>
    </>
  );
}

export default AboutUs;
